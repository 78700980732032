.instagram-analytics__section--advantages {
  padding: 80px 0;

  @include tablet {
    padding: 100px 0;
  }

  @include large-tablet {
    padding: 130px 0;
  }
}

.instagram-analytics__section--faq {
  padding-bottom: 80px;

  @include tablet {
    padding-bottom: 100px;
  }

  @include tablet {
    padding-bottom: 130px;
  }
}
