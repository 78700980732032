.home__section--hero {

}

.home__section--preview {
  position: relative;
  z-index: 1;
  background: linear-gradient(180deg, var(--background-color) 0%, var(--background-color) 50%, #FFFFFF 50%, #FFFFFF 100%);

  @include desktop {
    display: none;
  }
}

.home__section--brands {

}

.home__section--advantages {
  padding: 80px 0;

  @include tablet {
    padding: 100px 0;
  }

  @include large-tablet {
    padding: 130px 0;
  }
}
