@mixin mobile {
  @media (width >= 350px) {
    @content;
  }
}

@mixin large-mobile {
  @media (width >= 400px) {
    @content;
  }
}

@mixin small-tablet {
  @media (width >= 500px) {
    @content;
  }
}

@mixin tablet {
  @media (width >= 600px) {
    @content;
  }
}

@mixin large-tablet {
  @media (width >= 900px) {
    @content;
  }
}

@mixin desktop {
  @media (width >= 1200px) {
    @content;
  }
}

@mixin big-desktop {
  @media (width >= 1900px) {
    @content;
  }
}

@mixin short-hero-page {
  @media (height >= 800px) {
    @content;
  }
}

@mixin mobile-about-page-tablet {
  @media screen and (max-width: 600px) {
    @content;
  }
}

@mixin responsive-gap {
  @media (max-width: 1300px) {
    @content;
  }
}
