@import "utils/mixins";
@import "base/reset";
@import "manifest";
@import "typography";

.index {
  padding: 70px 15px 100px;
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.index__title {
  position: relative;
  margin-bottom: 20px;
}

.index__list {
  margin: 0;
  padding: 0;
}

.index__item {
  display: block;
  position: relative;
  list-style-type: none;
  padding-left: 20px;
  margin-bottom: 20px
}

.index__item:after {
  position: absolute;
  top: 6px;
  left: 4px;
  width: 7px;
  height: 7px;
  content: '';
  background-color: #F90043;
}

.index__link {
  color: #5a5a5a;
  text-decoration: none;
  border-bottom: 1px solid rgba(90, 90, 90, .6);
}

.index__link:hover {
  border-bottom-color: transparent;
}
