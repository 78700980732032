html,
body {
  width: 100%;
  height: 100%;
  color: var(--primary-text-color);
  font-size: 16px;
  line-height: 1.42;
  background: #fff;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

#credential_picker_container {
  top: 90px !important;
}

button {
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

a {
  -webkit-tap-highlight-color: transparent;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.body--fixed {
  overflow: hidden;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1360px;
  padding-left: var(--base-320-wrapper-padding);
  padding-right: var(--base-320-wrapper-padding);
  box-sizing: border-box;

  @include mobile {
    padding-left: var(--base-mobile-wrapper-padding);
    padding-right: var(--base-mobile-wrapper-padding);
  }

  @include tablet {
    padding-left: var(--base-desktop-wrapper-padding);
    padding-right: var(--base-desktop-wrapper-padding);
  }
}

.analysis--wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1360px;
  padding-left: var(--base-320-wrapper-padding);
  padding-right: var(--base-320-wrapper-padding);
  box-sizing: border-box;

  @include mobile {
    padding-left: var(--analysis-mobile-wrapper-padding);
    padding-right: var(--analysis-mobile-wrapper-padding);
  }

  @include tablet {
    padding-left: var(--base-desktop-wrapper-padding);
    padding-right: var(--base-desktop-wrapper-padding);
  }
}

.wrapper--extended {
  @include big-desktop {
    max-width: 1720px;
  }
}

.content {
  position: relative;
  min-height: 100%;
  height: auto;
  box-sizing: border-box;
}

.content--blog {
  padding-top: var(--base-header-height);

  @include tablet {
    padding-top: var(--base-header-desktop-height);
  }
}

.section--dark {
  background-color: var(--background-color);
}

.section--cta {
  @include desktop {
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      #ffffff 50%,
      var(--background-color) 50%,
      var(--background-color) 100%
    );
  }
}

.section--analysis {
  @include desktop {
    background: linear-gradient(
      180deg,
      var(--background-color) 0%,
      var(--background-color) 50%,
      #ffffff 50%,
      #ffffff 100%
    );
  }
}

.facebook--msg {
  display: flex;
  align-items: center;
  padding: 16px 0;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: -0.355556px;
  color: #8f909b;
  font-weight: 400;

  @include desktop {
    padding: 13px;
  }

  strong {
    font-weight: 700;
    margin-right: 4px;
  }
}
