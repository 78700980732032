div, h1, h2, h3, h4, h5, h6, li, ol, p, span, ul, dd, dl {
    margin: 0;
    padding: 0
}

li, ol, ul {
    list-style-type: none
}

button {
    outline: 0;
    border: 0;
    background: 0 0
}

a {
    text-decoration: none
}
