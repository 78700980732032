:root {
  --secondary-color: #4e74f5;
  --secondary-color-dark: #3c62e2;
  --primary-color: #f90043;
  --primary-color-dark: #e01249;
  --success-color: #02c39a;
  --waring-color: #ffb140;
  --primary-text-color: #000000;
  --primary-text-color-lighter: #2a2b2f;
  --secondary-text-color: #7a7b7d;
  --secondary-text-color-lighter: #a6a9b5;
  --background-color: #f5f6fa;
  --background-color-dark-gray: #f6f7fb;
  --main-white: #ffffff;
  --main-black: #000;
  --main-blue: #4E74F5;
  --tint-blue: #edf1fe;
  --gray-100: #f3f4f8;
  --gray-300: #E6E8EE;
  --gray-500: #a6a9b5;
  --gray-700: #38393f;

  --status-error: #f90043;

  --base-header-height: 60px;
  --base-header-desktop-height: 99px;

  --base-320-wrapper-padding: 15px;
  --base-mobile-wrapper-padding: 24px;
  --analysis-mobile-wrapper-padding: 8px;
  --base-desktop-wrapper-padding: 48px;

  --secondary-font-family: 'Inter', sans-serif;
}
