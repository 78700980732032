.about__section-container {
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -416px;
    width: 709px;
    height: 709px;
    background: linear-gradient(220.06deg, #F5F6FA 17.53%, rgba(245, 246, 250, 0) 87.1%);
    border-radius: 100%;

    @include tablet {
      width: 975px;
      height: 975px;
      left: 50%;
    }

    @include desktop {
      top: 100px;
    }
  }
}
