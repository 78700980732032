.button {
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 100%;
  min-width: 100px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 100px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;

  @include tablet {
    font-size: 16px;
    height: 64px;
    width: auto;
  }
}

.button__ico {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.button--small {
  min-width: 170px;
  height: 51px;
  font-size: 14px;
}

.button--full {
  width: 100%;
}

.button--ghost {
  color: var(--primary-text-color);

  @include desktop {
    &:hover {
      color: var(--secondary-color);
    }
  }
}

.button--round {
  border-radius: 100%;
  width: 64px;
  min-width: 64px;
}

.button--primary {
  background-color: var(--primary-color);

  @include desktop {
    &:hover {
      background-color: var(--primary-color-dark);
    }
  }
}

.button--secondary {
  background-color: var(--secondary-color);

  @include desktop {
    &:hover {
      background-color: var(--secondary-color-dark);
    }
  }
}

.button--light {
  background-color: #ffffff;
}
