@import 'fonts';

.main-title {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.375;

  @include mobile {
    font-size: 32px;
  }
}

.main-title--h1 {
  @include tablet {
    font-size: 60px;
  }
  @include big-desktop {
    font-size: 72px;
  }
}

.main-title--h2 {
  color: #2a2b2f;
  line-height: 1.35;
  @include mobile {
    width: 298px;
    font-size: 28px;
  }
  @include tablet {
    width: 500px;
    font-size: 48px;
  }
  @include desktop {
    width: 100%;
    font-size: 48px;
  }
}

.main-title--h3 {
  font-size: 18px;

  @include tablet {
    font-size: 20px;
  }
}

.main-text {
  font-size: 14px;
  line-height: 1.71;
  color: var(--secondary-text-color);

  @include tablet {
    font-size: 16px;
    line-height: 1.75;
  }
}

.main-text--wrap {
  white-space: pre-line;
}

.main-textTiles {
  font-size: 14px;
  line-height: 1.71;
  color: #808080;

  @include tablet {
    font-size: 16px;
    line-height: 1.75;
  }
}

.main-text--big {
  @include tablet {
    font-size: 18px;
    line-height: 1.77;
  }
}

.main-textTiles--big {
  @include tablet {
    font-size: 18px;
    line-height: 1.77;
  }
}

.main-link {
  display: inline-block;
  padding-bottom: 8px;
  font-weight: bold;
  font-size: 14px;
  color: var(--secondary-color);
  border-bottom: 2px solid var(--secondary-color);
  cursor: pointer;

  @include tablet {
    font-size: 16px;
  }

  &:hover {
    @include desktop {
      opacity: 0.8;
    }
  }
}

.page-title {
  margin-bottom: 8px;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.375;

  @include tablet {
    font-size: 48px;
    line-height: 1.25;
  }
  @include desktop {
    margin-bottom: 16px;
    font-size: 56px;
    line-height: 1.36;
  }

  @include big-desktop {
    font-size: 72px;
  }
}

.section-label {
  margin-bottom: 16px;
  font-weight: bold;
  color: var(--secondary-color);

  @include mobile {
    font-size: 12px;
  }
  @include tablet {
    font-size: 16px;
  }
}

.card-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: var(--main-black);

  @include tablet {
    font-size: 20px;
    line-height: 32px;
  }
}

.card-description {
  font-size: 14px;
  line-height: 20px;
  color: var(--main-black);
  opacity: 0.5;

  @include tablet {
    font-size: 16px;
    line-height: 28px;
  }
}
