.error-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  height: auto !important;
  box-sizing: border-box;
  background-color: var(--background-color);
}

.error-page__header {
  padding: 22px 16px;
  box-sizing: border-box;

  @include tablet {
    padding: 40px 54px;
  }
}

.error-page__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 38px;
  box-sizing: border-box;
}

.error-page__logo-wrapper {
  position: relative;
  display: block;
  width: 115px;
  height: 17px;


  @include tablet {
    width: 143px;
    height: 21px;
  }
}

.error-page__logo {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.error-page__img-wrapper {
  margin-bottom: 48px;
  position: relative;
  max-width: 220px;
  width: 100%;
  height: 136px;

  @include desktop {
    margin-bottom: 64px;
    max-width: 280px;
    height: 174px;
  }
}
.error-page__img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.error-page__title {
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: 600;
  line-height: 1.35;
  text-align: center;
}
.error-page__text {
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 1.75;
  color: var(--secondary-text-color);
  text-align: center;
}
.error-page__link {
  height: 56px;
  max-width: 300px;
  @include desktop {
    height: 64px;
  }
}
