input, textarea {
  &.error {
    border-color: var(--primary-color);
    &:focus {
      border-color: var(--primary-color);
    }
  }
}
.error-message {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: var(--primary-color);
}
